<template>
  <div class="mt-base">
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="mb-4 md:mb-0">
          <div class="flex space-x-2">
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3" @click="modalAdd.active = true">Tambah</vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <vs-table :data="initData.reports" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Aksi</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl Report</vs-th>
            <vs-th class="whitespace-no-wrap">Jumlah Klik</vs-th>
            <vs-th class="whitespace-no-wrap">Jumlah Tayang</vs-th>
            <vs-th class="whitespace-no-wrap">CTR</vs-th>
            <vs-th class="whitespace-no-wrap">Sisa Saldo</vs-th>
            <vs-th class="whitespace-no-wrap">File Report</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
            <vs-th class="whitespace-no-wrap">Created At</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr class="text-sm" v-for="(item, index) in data" :key="index">
              <vs-td class="whitespace-no-wrap">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                  <vs-dropdown-menu>
                    <!--<vs-dropdown-item class="p-1" @click="showModalEdit(item)"><span class="whitespace-no-wrap">Edit</span></vs-dropdown-item>-->
                    <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl_report }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.jumlah_klik }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.jumlah_tayang }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.ctr }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.sisa_saldo | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <a v-if="item.file_url" :href="item.file_url" target="_blank">
                  <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                </a>
              </vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <!--modals-->
    <PengajuanMediaReportAdd :isActive.sync="modalAdd.active" :id-pengajuan-media="initData.header.id" @success="emitNeedRRefresh"/>
  </div>
</template>

<script>
import PengajuanMediaRepository from '@/repositories/marketing/pengajuan-media-repository'

export default {
  name: 'TabReports',
  components: {
    PengajuanMediaReportAdd: () => import('@/views/pages/marketing/pengajuan-media/tabs/tab-reports/PengajuanMediaReportAdd')
  },
  computed: {
    storePengajuanMediaShow () {
      return this.$store.state.marketing.pengajuanMediaShow
    },
    initData: {
      get () {
        return this.storePengajuanMediaShow.initData
      },
      set (value) {
        this.$store.commit('marketing/pengajuanMediaShow/SET_INIT_DATA', value)
      }
    }
  },
  data () {
    return {
      modalAdd: {
        active: false
      }
    }
  },
  methods: {
    delete (id) {
      PengajuanMediaRepository.deleteReport(id)
        .then(response => {
          this.emitNeedRRefresh()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit () {
      //
    },

    emitNeedRRefresh () {
      this.$emit('needRefresh')
    }
  }
}
</script>
